/* eslint-disable react-hooks/exhaustive-deps */
import DetailsHeader from "../../../../components/borrowerComponents/DetailsHeader";
import InputField from "../../../../components/formComponents/fieldComponents/InputField";
import CButton from "../../../../components/formComponents/CButton";
import FilterListIcon from "@mui/icons-material/FilterList";
import CTable from "../../../../components/table/CTable";
import { useEffect, useState } from "react";
import CModal from "../../../../components/modal/CModal";
import BorrowerDetails from "../borrowerDetails";
import EditIcon from "@mui/icons-material/Edit";
import { useDispatch, useSelector } from "react-redux";
import { borrower } from "../../../../data/models/types/borrower";
import {
  selectedBorrowerId,
  setLoading,
} from "../../../../data/store/slices/utilSlice";
import {
  getAccountsByBorrower,
  getBorrower,
} from "../../../../data/api/borrowerAPI";
import { borrowerResponse } from "../../../../data/models/response/borrowerResponse";
import { AccountByBorrowerResponse } from "../../../../data/models/response/accountByBorrowerResponse";
import { createTableHeaders } from "../../../../utils/helperFunctions";
import EditBorrower from "../editBorrower";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { Account } from "../../../../data/models/types/Account";
import { useNavigate } from "react-router-dom";
import notification from "../../../../utils/toast";
import { setLoadingWithDelay } from "../../../../data/store/thunk/utilThunk";

interface IBorrower {}

const Borrower = (props: IBorrower) => {
  const lender: any = localStorage.getItem("lenderName");
  const lenderId: any = localStorage.getItem("lenderId");
  const currency: string | null = localStorage.getItem("currency");

  const borrowerId: number = useSelector(selectedBorrowerId);
  const [open, setOpen] = useState<boolean>(false);
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [borrower, setBorrower] = useState<borrower>();
  const [tableFilter, setTableFilter] = useState<string>("");
  const [searchString, setSearchString] = useState<string>("");
  const [accounts, setAccounts] = useState<Account[]>([]);
  const [accountsCount, setAccountsCount] = useState<number>(0);
  const [headers, setHeaders] = useState<any>([]);
  const dispatch: any = useDispatch();
  const navigate = useNavigate();

  const tableFilters = [
    "All",
    "In Collection",
    "Payment Plan",
    "Scheduled Payment",
    "Settled",
    "Disputed",
  ];

  const detailHeaderAttributes = [
    {
      name: "Email",
      value: borrower?.email ? borrower?.email : "-",
      icon: <ContentCopyIcon className="w-[18px] h-[18px] relative" />,
    },
    {
      name: "Mobile",
      value: borrower?.phone ? "+" + borrower?.phone : "-",
    },
    {
      name: "National ID",
      value: borrower?.idNumber ? borrower?.idNumber : "-",
    },
    {
      name: "Total Outstanding Balance",
      value: borrower?.outstandingAmount
        ? `${currency} ` + borrower?.outstandingAmount
        : "-",
      bold: true,
    },
  ];

  useEffect(() => {
    getBorrowerById();
  }, []);

  useEffect(() => {
    getAccounts(10, 0);
  }, [tableFilter, searchString]);

  const getAccounts = async (size: number, page: number) => {
    try {
      dispatch(setLoading(true));
      const res: AccountByBorrowerResponse | undefined =
        await getAccountsByBorrower({
          page,
          size,
          lender,
          borrowerId,
          filter: tableFilter,
          searchString,
        });

      if (res) {
        console.log(res);
        setAccounts(res?.accounts!);
        setAccountsCount(res?.total!);
        setHeaders(createTableHeaders(res?.headers!));
      }
      dispatch(setLoadingWithDelay(false));
    } catch (error: any) {
      notification(error.message, "error");
      dispatch(setLoadingWithDelay(false));
    }
  };

  const getBorrowerById = async () => {
    try {
      dispatch(setLoading(true));
      const res: borrowerResponse | undefined = await getBorrower({
        lender,
        borrowerId,
      });

      if (res) {
        const borrower: borrower | undefined = res?.borrower;
        setBorrower(borrower);
        setIsEditing(false);
      }
      dispatch(setLoadingWithDelay(false));
    } catch (error: any) {
      notification(error.message, "error");
      dispatch(setLoadingWithDelay(false));
    }
  };

  const onFilterClick = (filterIndex: number) => {
    const filterProperty = tableFilters[filterIndex];
    switch (filterProperty) {
      case "All":
        setTableFilter("");
        break;
      case "In Collection":
        setTableFilter("in-collection");
        break;
      case "Payment Plan":
        setTableFilter("payment-plan");
        break;
      case "Scheduled Payment":
        setTableFilter("scheduled");
        break;
      case "Settled":
        setTableFilter("resolved");
        break;
      case "Disputed":
        setTableFilter("disputed");
        break;

      default:
        break;
    }
  };

  const setOpenCustom = (state: boolean) => {
    setOpen(state);
    setIsEditing(false);
  };

  const onRowClick = (rowId?: number, row?: any) => {
    navigate("/account", {
      state: { borrowerid: row.borrowerPublicID, accountId: rowId },
    });
  };

  return (
    <div>
      <DetailsHeader
        borrowerName={borrower?.fullName}
        properties={{ type: "borrower", attributes: detailHeaderAttributes }}
        viewDetails
        openViewDetailsModal={() => setOpen(true)}
      />
      <div className="px-10 py-8">
        <h1 className="text-xl font-semibold leading-8 mb-5">All Accounts</h1>
        <div className="flex justify-between items-end">
          <div className="flex items-end">
            <InputField
              className="grow lg:w-[560px]"
              placeholder="Search by reference id, status etc..."
              search={true}
              onSearchSubmit={setSearchString}
              name="search"
            />
            <CButton
              className="ml-3"
              type="outlined"
              varient="icon-button"
              icon={<FilterListIcon />}
            />
          </div>
        </div>
        <div className="mt-8">
          <CTable
            headCells={headers}
            rows={accounts}
            tabs={true}
            minWidth={750}
            tableFilters={tableFilters}
            totalRows={accountsCount}
            filterAction={onFilterClick}
            refreshDataAPI={getAccounts}
            onRowClick={onRowClick}
            rowIdentifier="accountPublicId"
          />
        </div>
      </div>

      <CModal
        title="Borrowers Details"
        body={
          isEditing ? (
            <EditBorrower
              borrower={borrower}
              refreshAccount={getBorrowerById}
            />
          ) : (
            <BorrowerDetails borrower={borrower} />
          )
        }
        open={open}
        setOpen={setOpenCustom}
        size="lg"
        footer={false}
        customActionButtons={
          <div className="border-t border-solid border-blueGray-200">
            {
              <div className="flex justify-end w-full py-2 px-6">
                {!isEditing && (
                  <CButton
                    text={"Edit Details"}
                    type="contained"
                    onClick={() => {
                      setIsEditing(true);
                    }}
                    icon={<EditIcon />}
                  />
                )}

                {/* {isEditing && (
                  <CButton
                    text={"Save Details"}
                    type="contained"
                    icon={<EditIcon />}
                    form="edit-borrower"
                    submit={true}
                  />
                )} */}
              </div>
            }
          </div>
        }
      />
    </div>
  );
};

export default Borrower;
